import { clsx } from "clsx"
import { extendTailwindMerge } from "tailwind-merge";
import { withExtendedShadows } from "tailwind-extended-shadows-merge";

export const twMerge = extendTailwindMerge(withExtendedShadows);

const cn = function (...inputs) {
    return twMerge(clsx(inputs))
}

const calcInitialZoom = function (canvasWidth, canvasHeight, headerheight = 70) {
    // Get the viewport width and height
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight - headerheight; // Subtract the header height

    // Calculate the width and height ratios
    const widthRatio = viewportWidth / canvasWidth;
    const heightRatio = viewportHeight / canvasHeight;

    // Use the smaller ratio to ensure the canvas fits within the viewport and maintains the aspect ratio
    const zoomLevel = Math.min(widthRatio, heightRatio);

    return zoomLevel;
}

const getBase64FileSize = (base64String) => {
    // Remove the data URL prefix if it exists
    const base64Data = base64String.split(',')[1] || base64String;
    // Calculate the padding
    const padding = (base64Data.match(/=*$/)[0] || '').length;
    // Calculate the file size in bytes
    const fileSizeInBytes = (base64Data.length * 3) / 4 - padding;
    // Convert to human readable format
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = fileSizeInBytes;
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${Math.round(size * 10) / 10}${units[unitIndex]}`;
}



export { cn, calcInitialZoom, getBase64FileSize }
